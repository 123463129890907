import { css } from "@emotion/react";
import { useAuth } from "@clerk/nextjs";
import {
  Box,
  Typography,
  IconButton,
  LinearProgress,
  Link,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  QuestionMark as QuestionMarkIcon,
  Close as CloseIcon,
} from "@mui/icons-material";

import { useState } from "react";
import { api } from "~/utils/api";
import {
  PromptSettingsModal,
  usePrompSettingsModal,
} from "./PromptSettingsModal";

enum ShouldUsePrompt {
  NoPrompt = "noPrompt",
  YesPrompt = "yesPrompt",
}
export const PromptSelector = ({
  className,
  onPromptChange,
  disabled,
}: {
  className?: string;
  disabled?: boolean;
  onPromptChange: (prompt?: { id: string; value: string }) => void;
}) => {
  const { isSignedIn } = useAuth();
  const { openModal: openPromptSettingsModal } = usePrompSettingsModal();

  const { data: selectedPromptTags, isInitialLoading: isUserLoading } =
    api.journalingPrompts.getCurrentUserTags.useQuery(undefined, {
      enabled: !!isSignedIn,
    });

  const [currentPromptIndex, setCurrentPromptIndex] = useState(0);
  const handleSetCurrentPromptIndex = (newIndex: number) => {
    setCurrentPromptIndex(newIndex);
    onPromptChange(prompts[newIndex]);
  };
  const { data: prompts = [], isLoading: arePromptsLoading } =
    api.journalingPrompts.getPrompts.useQuery(
      {
        tags: isSignedIn
          ? selectedPromptTags?.map(({ id }) => id) ?? []
          : undefined,
      },
      {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        enabled: !!selectedPromptTags || !isSignedIn,
        onSuccess: (prompts) => {
          if (prompts.length > 0) {
            const newIndex = Math.floor(Math.random() * prompts.length);
            setCurrentPromptIndex(newIndex);
            onPromptChange(prompts[newIndex]);
          }
        },
      }
    );

  const isPageLoading = (!!isSignedIn && isUserLoading) || arePromptsLoading;

  const [shouldUsePromptSelection, setShouldUsePromptSelection] =
    useState<ShouldUsePrompt>(ShouldUsePrompt.NoPrompt);

  return (
    <>
      <Box className={className}>
        <ToggleButtonGroup
          value={shouldUsePromptSelection}
          exclusive
          onChange={(_, value: ShouldUsePrompt) => {
            if (value === ShouldUsePrompt.NoPrompt) {
              handleSetCurrentPromptIndex(-1);
            } else if (value === ShouldUsePrompt.YesPrompt) {
              const newRandomIndex = Math.floor(Math.random() * prompts.length);
              if (newRandomIndex !== currentPromptIndex) {
                handleSetCurrentPromptIndex(newRandomIndex);
              } else if (newRandomIndex > 0) {
                handleSetCurrentPromptIndex(newRandomIndex - 1);
              } else {
                handleSetCurrentPromptIndex(newRandomIndex + 1);
              }
            }
            setShouldUsePromptSelection(value);
          }}
          css={css`
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
            & > button {
              flex: 1 1 100px;
            }
          `}
        >
          <ToggleButton value={ShouldUsePrompt.NoPrompt}>
            No Prompt
          </ToggleButton>
          <ToggleButton value={ShouldUsePrompt.YesPrompt}>
            Use Prompt
          </ToggleButton>
        </ToggleButtonGroup>

        {shouldUsePromptSelection === ShouldUsePrompt.YesPrompt && (
          <>
            {isPageLoading ? (
              <Box className={className}>
                <LinearProgress />
              </Box>
            ) : (
              <>
                <Box
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    width: 100%;
                    padding: 8px 0;
                  `}
                >
                  <IconButton
                    disabled={disabled}
                    onClick={() => {
                      if (currentPromptIndex <= 0) {
                        handleSetCurrentPromptIndex(prompts.length - 1);
                      } else {
                        handleSetCurrentPromptIndex(currentPromptIndex - 1);
                      }
                    }}
                    css={css`
                      color: #5f1b14;
                    `}
                  >
                    <ChevronLeftIcon fontSize="small" />
                  </IconButton>

                  <IconButton
                    disabled={disabled}
                    onClick={() => {
                      if (currentPromptIndex + 1 === prompts.length) {
                        handleSetCurrentPromptIndex(0);
                      } else {
                        handleSetCurrentPromptIndex(currentPromptIndex + 1);
                      }
                    }}
                    css={css`
                      margin-left: auto;
                      color: #5f1b14;
                    `}
                  >
                    <ChevronRightIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      const newRandomIndex = Math.floor(
                        Math.random() * prompts.length
                      );
                      if (newRandomIndex !== currentPromptIndex) {
                        handleSetCurrentPromptIndex(newRandomIndex);
                      } else if (newRandomIndex > 0) {
                        handleSetCurrentPromptIndex(newRandomIndex - 1);
                      } else {
                        handleSetCurrentPromptIndex(newRandomIndex + 1);
                      }
                    }}
                    disabled={prompts.length <= 1 || disabled}
                    css={css`
                      color: #5f1b14;
                    `}
                  >
                    <QuestionMarkIcon
                      css={css`
                        font-size: 16px;
                      `}
                    />
                  </IconButton>
                </Box>
                <Typography
                  variant="body1"
                  css={css`
                    font-size: 18px;
                    opacity: ${prompts[currentPromptIndex]?.value ? 1 : 0.5};
                    padding: 0 8px;
                    display: flex;
                  `}
                >
                  {prompts[currentPromptIndex]?.value ?? ""}
                </Typography>
                <Box
                  css={css`
                    display: flex;
                    flex-wrap: wrap;
                    gap: 8px;
                  `}
                >
                  {isSignedIn && (
                    <Typography
                      variant="body2"
                      css={css`
                        margin-left: auto;
                        text-align: right;
                        opacity: 0.7;
                        padding-top: 8px;
                      `}
                    >
                      <Link onClick={openPromptSettingsModal}>
                        Customize Prompts
                      </Link>
                    </Typography>
                  )}
                </Box>
              </>
            )}
          </>
        )}
      </Box>
      <PromptSettingsModal />
    </>
  );
};
