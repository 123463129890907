import { useUser } from "@clerk/nextjs";
import { css } from "@emotion/react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import Link from "next/link";
import { useModal, useToast } from "~/context";
import { api } from "~/utils/api";
import { prettyDate } from "~/utils/prettyDate";

export type UnsavedChanges = Record<
  string,
  { hasUnsavedChanges: boolean; label: string }
>;

const agreementTypeInfo = {
  // communityGuidelines: {
  //   label: "Community Guidelines",
  //   path: "/community-guidelines",
  // },
  privacyPolicy: { label: "Privacy Policy", path: "/privacy" },
  termsOfService: { label: "Terms of Service", path: "/terms" },
  beta: { label: "Beta Agreement", path: "/beta" },
};

const AGREEMENTS_MODAL_ID = "agreements-modal";
export const useAgreementsModal = (
  props?: Partial<Pick<Parameters<typeof useModal>[0], "onClose" | "onOpen">>
) =>
  useModal({
    modalId: AGREEMENTS_MODAL_ID,
    onClose: props?.onClose,
    onOpen: props?.onOpen,
  });
export const AgreementsModal = () => {
  const { isSignedIn } = useUser();
  const { isOpen, closeModal } = useAgreementsModal();
  const { setToast } = useToast();
  const context = api.useContext();

  const { data: agreements } =
    api.agreements.getHasCurrentUserAcceptedLatestAgreements.useQuery(
      undefined,
      {
        enabled: !!isSignedIn,
      }
    );

  const { mutate: agreeToLatestAgreements, isLoading: isAgreeing } =
    api.agreements.setCurrentUserAcceptedLatestAgreements.useMutation();

  return (
    <Dialog open={isOpen} fullWidth maxWidth="sm">
      <DialogTitle
        css={css`
          display: flex;
          flex-wrap: wrap;
          gap: 0 8px;
        `}
      >
        <Typography
          variant="h5"
          css={css`
            flex: 1 1 200px;
          `}
        >
          {/* TODO: get copy */}
          New Agreements
        </Typography>
      </DialogTitle>
      <DialogContent
        css={css`
          display: flex;
          flex-direction: column;
          gap: 16px;
        `}
      >
        <Box>
          <Typography variant="body1">
            The following agreements have been updated since you last accepted.
            Please review and accept them to continue using the app.
          </Typography>
          {agreements?.newAgreements.map(({ id, name, agreements }) => (
            <ListItemButton
              component={Link}
              href={
                agreementTypeInfo[name as keyof typeof agreementTypeInfo].path
              }
              target="_blank"
              key={id}
            >
              <ListItemText
                primary={
                  agreementTypeInfo[name as keyof typeof agreementTypeInfo]
                    .label
                }
                secondary={`Updated: ${prettyDate(agreements[0]?.updatedAt)}`}
              />
            </ListItemButton>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() =>
            agreeToLatestAgreements(undefined, {
              onSuccess: () => {
                void context.agreements.getHasCurrentUserAcceptedLatestAgreements
                  .invalidate()
                  .then(() => {
                    setToast({
                      message: "Agreements accepted!",
                      severity: "success",
                    });
                    closeModal();
                  });
              },
            })
          }
          disabled={isAgreeing}
        >
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};
